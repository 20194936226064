import {
  BellFilled,
  DownOutlined,
  LogoutOutlined,
  MenuOutlined,
  SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, MenuProps, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";

export default function Header({
  setLoggedIn,
  menuOpen,
  setMenuOpen,
}: {
  setLoggedIn: (value: boolean) => void;
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
}) {
  const navigate = useNavigate();
  const items: MenuProps["items"] = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: <Link to="/profile">Profile</Link>,
    },
    {
      key: "2",
      label: (
        <a
          className="!duration-0"
          onClick={() => {
            localStorage.removeItem("admin-token");
            setLoggedIn(false);
            navigate("/login");
          }}
        >
          Logout
        </a>
      ),
      danger: true,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <div className="flex justify-between items-center bg-indigo-500 text-white p-4">
      <div className="flex items-center">
        <div className="mr-4 text-xl">
          <MenuOutlined
            onClick={() => setMenuOpen(!menuOpen)}
            className="mr-2"
          />
          <Link to="/">DB Manchester</Link>
        </div>
      </div>
      <div className="flex items-center">
        <BellFilled className="mr-4 text-xl" />
        <Dropdown menu={{ items }}>
          <UserOutlined className="mr-2 text-xl bg-white text-indigo-500 rounded-full p-1" />
        </Dropdown>
      </div>
    </div>
  );
}
