interface F {
  (
    url: string,
    data: Record<string, string | number | boolean | Object>,
    method?: "GET" | "POST" | "DELETE" | "PUT",
    headers?: Record<string, string>
  ): Promise<{
    success: boolean;
    data: Record<string, any>;
    error: string;
  }>;
}

const f: F = async (url, data, method = "POST", headers = {}) => {
  const token = localStorage.getItem("admin-token") || "";
  let success = false,
    error = "",
    resData = {};
  let u = process.env.REACT_APP_BACKEND_URL + "/admin" + url;
  if (method === "GET") {
    u += "?";
    for (const key in data) {
      u += `${key}=${data[key]}&`;
    }
    u += `t=${new Date().getTime()}`;
  }
  try {
    const response: Response = await fetch(u, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      body: method === "GET" ? undefined : JSON.stringify(data),
    });
    const d = await response.json();
    if (d.success === true) {
      success = true;
      resData = d.data;
      error = "";
      return { success, data: resData, error };
    }
    success = false;
    resData = {};
    error = d.error;
    return { success, data: resData, error };
  } catch (err) {
    success = false;
    resData = {};
    error = "Network error. Please try again.";
    return { success, data: resData, error };
  }
};

export default f;
