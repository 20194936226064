import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Splash from "./components/Splash";
import f from "./components/f";
import H from "./admin/shared/Header";
import Menu from "./admin/shared/Menu";

const Login = lazy(() => import("./admin/account/Login"));
const ResetPassword = lazy(() => import("./admin/account/ResetPassword"));
const NotFound = lazy(() => import("./components/NotFound"));
const Home = lazy(() => import("./admin/account/Home"));
const Profile = lazy(() => import("./admin/account/Profile"));
const FmbMenu = lazy(() => import("./admin/fmb/FmbMenu"));
const FmbFeedback = lazy(() => import("./admin/fmb/FmbFeedback"));
const FmbItems = lazy(() => import("./admin/fmb/FmbItems"));
const FmbAllergens = lazy(() => import("./admin/fmb/FmbAllergens"));
const FmbDonations = lazy(() => import("./admin/fmb/FmbDonations"));
const SabilPayments = lazy(() => import("./admin/sabil/SabilPayments"));
const SabilTakhmeen = lazy(() => import("./admin/sabil/SabilTakhmeen"));
const SabilEdit = lazy(() => import("./admin/sabil/SabilEdit"));
const SabilReport = lazy(() => import("./admin/sabil/SabilReport"));
const UsersView = lazy(() => import("./admin/users/UsersView"));
const UsersAdd = lazy(() => import("./admin/users/UsersAdd"));
const AdminView = lazy(() => import("./admin/admin/AdminView"));
const AdminAdd = lazy(() => import("./admin/admin/AdminAdd"));
const Notifications = lazy(() => import("./admin/notifications/Notify"));
const Rsvp = lazy(() => import("./admin/notifications/Rsvp"));

function App() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggenIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);

  const login = () => {
    setLoading(false);
    if (window.location.pathname === "/login") return;
    if (window.location.pathname === "/reset-password") return;
    navigate("/login");
  };

  const auth = async () => {
    const auth = await f("/auth/verify", {}, "GET");
    if (!auth.success) return login();
    setLoading(false);
    setLoggenIn(true);
  };

  useEffect(() => {
    if (!localStorage.getItem("admin-token")) return login();
    auth();
  }, []);

  return (
    <Suspense fallback={<Splash />}>
      <div className="flex flex-col h-screen">
        {loading && <Splash />}
        {loggedIn && (
          <H
            setLoggedIn={setLoggenIn}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
          />
        )}
        <div className="flex flex-1">
          {loggedIn && <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />}
          <div
            className="flex-1 bg-slate-200"
            style={
              loggedIn
                ? menuOpen
                  ? { minHeight: "calc(100vh - 60px)", padding: "20px" }
                  : { minHeight: "calc(100vh - 60px)", padding: "20px" }
                : { minHeight: "calc(100vh)" }
            }
          >
            <Routes>
              <Route
                path="login"
                element={
                  <Login loggedIn={loggedIn} setLoggedIn={setLoggenIn} />
                }
              />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="profile" element={<Profile />} />
              <Route path="" element={<Home />} />
              <Route path="fmb/menu" element={<FmbMenu />} />
              <Route path="fmb/feedback" element={<FmbFeedback />} />
              <Route path="fmb/items" element={<FmbItems />} />
              <Route path="fmb/allergens" element={<FmbAllergens />} />
              <Route path="fmb/donations" element={<FmbDonations />} />
              <Route path="sabil/payments" element={<SabilPayments />} />
              <Route path="sabil/takhmeen" element={<SabilTakhmeen />} />
              <Route path="sabil/edit" element={<SabilEdit />} />
              <Route path="sabil/report" element={<SabilReport />} />
              <Route path="users/list" element={<UsersView />} />
              <Route path="users/add" element={<UsersAdd />} />
              <Route path="admin/view" element={<AdminView />} />
              <Route path="admin/create" element={<AdminAdd />} />
              <Route path="notifications/notify" element={<Notifications />} />
              <Route path="notifications/rsvp" element={<Rsvp />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </Suspense>
  );
}

export default App;
