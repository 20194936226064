export default function Splash() {
  return (
    <div className="flex items-center justify-center h-screen fixed w-full z-50 t-0 bg-slate-200 text-indigo-500">
      <h1 className="text-4xl font-bold text-center">
        Dawoodi Bohra Jamaat
        <br />
        <small className="text-lg text-indigo-500">Manchester</small>
      </h1>
    </div>
  );
}
