import React from "react";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu as M } from "antd";
import { useNavigate } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    key: "fmb",
    label: "FMB",
    children: [
      { key: "menu", label: "Menu" },
      { key: "feedback", label: "Feedbacks" },
      { key: "items", label: "Items" },
      { key: "allergens", label: "Allergens" },
      { key: "donations", label: "Donations" },
    ],
  },
  {
    type: "divider",
  },
  {
    key: "sabil",
    label: "Sabil",
    children: [
      // { key: "payments", label: "Payments" },
      { key: "takhmeen", label: "Takhmeen" },
      { key: "edit", label: "View Sabil" },
      // { key: "report", label: "Reports" },
    ],
  },
  {
    type: "divider",
  },
  {
    key: "notifications",
    label: "Notifications",
    children: [
      { key: "rsvp", label: "RSVP" },
      { key: "notify", label: "Notify" },
    ],
  },
  {
    type: "divider",
  },
  // {
  //   key: "users",
  //   label: "Users",
  //   children: [
  //     { key: "list", label: "View" },
  //     { key: "add", label: "Add" },
  //   ],
  // },
  // {
  //   type: "divider",
  // },
  // {
  //   key: "admin",
  //   label: "Admin",
  //   children: [
  //     { key: "view", label: "View" },
  //     { key: "create", label: "Create" },
  //   ],
  // },
  // {
  //   type: "divider",
  // },
];

const Menu = ({
  menuOpen,
  setMenuOpen,
}: {
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
}) => {
  const n = useNavigate();
  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "menu") return n("/fmb/menu");
    if (e.key === "feedback") return n("/fmb/feedback");
    if (e.key === "items") return n("/fmb/items");
    if (e.key === "allergens") return n("/fmb/allergens");
    if (e.key === "donations") return n("/fmb/donations");
    if (e.key === "payments") return n("/sabil/payments");
    if (e.key === "takhmeen") return n("/sabil/takhmeen");
    if (e.key === "edit") return n("/sabil/edit");
    if (e.key === "report") return n("/sabil/report");
    if (e.key === "list") return n("/users/list");
    if (e.key === "add") return n("/users/add");
    if (e.key === "view") return n("/admin/view");
    if (e.key === "create") return n("/admin/create");
    if (e.key === "notify") return n("/notifications/notify");
    if (e.key === "rsvp") return n("notifications/rsvp");
  };

  return (
    <M
      className="fixed h-screen left-0 bg-white z-10 lg:relative"
      onClick={onClick}
      style={
        menuOpen
          ? { width: 256, overflowY: "auto", maxHeight: "calc(100vh - 60px)" }
          : { width: 0, overflow: "hidden" }
      }
      defaultOpenKeys={[window.location.pathname.split("/")[1] || ""]}
      defaultSelectedKeys={[window.location.pathname.split("/")[2] || ""]}
      mode="inline"
      items={items}
    />
  );
};

export default Menu;
